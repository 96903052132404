import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layout'
import Img from 'gatsby-image'

const ImpressumPage = ({ about }) => {
    const data = useStaticQuery(graphql`
    {
        ubermich: strapiSitePage(strapiId: {eq: 1}) {
          title
          subtitle
          content
          image {
            childImageSharp {
                fluid(maxWidth: 512) {
                    ...GatsbyImageSharpFluid
                }
                }
        }
        }
        
    }
    `

    )

    return (
<Layout>
    <div className="w-full p-6 flex flex-col flex-grow flex-shrink">
        <div className="flex-1 bg-white dark:bg-gray-800 rounded-t rounded-b-none overflow-hidden">

          <p className="w-full text-4xl md:text-4xl font-medium mt-6 px-6 uppercase dark:text-gray-400">{data.ubermich.title}</p>
          <div className="w-full font-light md:text-2xl text-joker px-6 italic dark:text-gray-200">{data.ubermich.subtitle}</div>

          <Img className="hover:grow hover:shadow-xl max-w-lg ml-6 mt-6 rounded-lg shadow" fluid={data.ubermich.image.childImageSharp.fluid} alt={data.ubermich.title}/>
          <p className="text-gray-800 font-sans text-base px-6 mb-5 mt-6 leading-relaxed dark:text-gray-200">
          <ReactMarkdown source={data.ubermich.content}
        transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
      />
            
          </p>
     
        </div>
      </div>

      </Layout>
 )
}

export default ImpressumPage